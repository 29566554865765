import React from 'react';
import { observer } from 'mobx-react';
import { observable, action, makeObservable } from 'mobx';

import Image, { ImageData } from '../Image';
import { Sizes } from '../../constants/Image';

import './styles/Images.scss';

interface Props {
  images?: ImageData[];
  productSlug?: string;
  productNew?: boolean;
  prices?: [];
}

@observer
export default class Images extends React.Component<Props> {
  @observable currentProductSlug = '';
  @observable activeImage = null;

  constructor(props: Props) {
    super(props);
    makeObservable(this);
  }

  componentDidMount() {
    this.update();
  }

  componentDidUpdate() {
    this.update();
  }

  update() {
    const { images, productSlug } = this.props;

    if (productSlug === this.currentProductSlug) {
      return;
    }
    this.currentProductSlug = productSlug;

    const cover = images ? images[0] : undefined;

    this.setActiveImage(cover);
  }

  @action setActiveImage(image) {
    this.activeImage = image;
  }

  public render() {
    const { images } = this.props;

    const thumbs = images.map((image) => (
      <div
        key={image.id}
        className="thumbnails-image"
        onClick={this.setActiveImage.bind(this, image)}
      >
        <Image
          image={image}
          key={image.id}
          defaultSize={Sizes.W100}
          allSizes={[Sizes.W100, Sizes.W540]}
          className={`${this.activeImage && image.id === this.activeImage.id ? 'active' : ''} rounded bg-rgrey-lightlight object-cover w-28 h-auto `}
          width="100"
          height="100"
        />
      </div>
    ));

    return (
      <div className={`images`}>
        <div className="thumbnails">{thumbs}</div>
        <div className="image-wrap">
          <div className="image">
            <Image
              className={
                'rounded bg-rgrey-lightlight object-cover w-[560px] md:w-[800px] h-auto'
              }
              defaultSize={Sizes.W800}
              image={this.activeImage}
              allSizes={[Sizes.W540, Sizes.W800, Sizes.W1600]}
            />
          </div>
        </div>
      </div>
    );
  }
}
