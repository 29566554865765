import React from 'react';
import { observer, inject } from 'mobx-react';
import { observable, action, makeObservable } from 'mobx';

import ProductsStore from '../../stores/Products';
import Product from '../../models/Product';
import ProductBlock from '../ProductBlock';
import Slider, { LazyLoadTypes } from 'react-slick';
import parse from 'html-react-parser';

import './styles/Recommendations.scss';

interface Props {
  productSlug?: string;
  products?: ProductsStore;
}

@inject('products')
@observer
export default class Series extends React.Component<Props> {
  @observable currentProductSlug = '';
  @observable title = '';
  @observable description = '';
  @observable series = [];
  @observable loading = true;

  constructor(props: Props) {
    super(props);
    makeObservable(this);
  }

  componentDidMount() {
    this.loadSeries();
  }

  componentDidUpdate() {
    this.loadSeries();
  }

  async loadSeries() {
    const { productSlug, products } = this.props;

    if (productSlug === this.currentProductSlug) {
      return;
    }

    this.currentProductSlug = productSlug;
    this.setProducts(await products.loadSeries(productSlug));
  }

  @action setProducts(serie) {
    if (serie?.products?.length) {
      this.title = serie.title;
      this.description = serie.description;
      this.series = serie.products.map((product) => new Product(product));
    }
    this.loading = false;
  }

  public render() {
    if (this.loading || this.series.length === 0) {
      return null;
    }

    const sliderConfig = {
      className: 'block-slider',
      lazyLoad: 'ondemand' as LazyLoadTypes,
      slidesToShow: this.series.length,
      dots: true,
      dotsClass: 'awesome-dots',
      customPaging: () => {
        return <span />;
      },
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: this.series.length > 2 ? 3 : this.series.length,
          },
        },
        {
          breakpoint: 768,
          settings: {
            infinite: false,
            slidesToShow: 1.25,
            slidesToScroll: 1,
          },
        },
      ],
    };

    const products = this.series.map((product) => (
      <ProductBlock key={product.slug} product={product} slider={true} />
    ));

    return (
      <div className="recommendations">
        <h2>{this.title}</h2>
        <div className="serie-description">{parse(this.description)}</div>
        <div className="products-slick">
          <Slider {...sliderConfig}>{products}</Slider>
        </div>
      </div>
    );
  }
}
