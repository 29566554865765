import React from 'react';
import { useLocation } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import LocalizedLink from '../LocalizedLink';
import Translate from '../Translate/Translate';
import './Breadcrumbs.scss';

const GET_PATH = gql`
  query GetPath($slug: String!) {
    path(slug: $slug) {
      slug
      name
    }
  }
`;

interface BreadcrumbsProps {
  slug: string;
}

const Breadcrumbs = ({ slug }: BreadcrumbsProps) => {
  const { data, previousData } = useQuery(GET_PATH, { variables: { slug } });
  const path = data?.path || previousData?.path || [];

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  queryParams.delete('page');

  const crumbs = [];
  path.forEach((pathItem, index) => {
    const categorySlug = index === 0 ? '/tooted' : '';
    crumbs.push({
      name: pathItem.name,
      path: `${index > 0 ? crumbs[index - 1].path : ''}${categorySlug}/${
        pathItem.slug
      }`,
    });
  });

  return (
    <div className="breadcrumbs w-full">
      <LocalizedLink to="/">
        <Translate component="breadcrumbs" keyword="avaleht" />
      </LocalizedLink>
      {crumbs.map((crumb) => {
        return (
          <LocalizedLink to={`${crumb.path}?${queryParams}`} key={crumb.path}>
            {crumb.name}
          </LocalizedLink>
        );
      })}
    </div>
  );
};

export default Breadcrumbs;
