import React from 'react';
import { observer, inject } from 'mobx-react';
import { observable, action, makeObservable } from 'mobx';

import ProductsStore from '../../stores/Products';
import Product from '../../models/Product';
import ProductBlock from '../ProductBlock';
import Left from '../SlickArrow/Left';
import Right from '../SlickArrow/Right';

import Slider from 'react-slick';

import './styles/Recommendations.scss';

interface Props {
  productSlug?: string;
  products?: ProductsStore;
  title?: React.ReactNode;
}

@inject('products')
@observer
export default class Latest extends React.Component<Props> {
  @observable currentProductSlug = '';
  @observable currentTitle = null;
  @observable recommendations = [];
  @observable loading = true;

  constructor(props: Props) {
    super(props);
    makeObservable(this);
  }

  componentDidMount() {
    this.loadLatest();
  }

  componentDidUpdate() {
    this.loadLatest();
  }

  async loadLatest() {
    const { productSlug, products, title } = this.props;

    if (productSlug === this.currentProductSlug) {
      return;
    }

    this.currentProductSlug = productSlug;
    this.currentTitle = title;

    this.setProducts(await products.loadLatest(productSlug));
  }

  @action setProducts(products) {
    this.recommendations = products.map((product) => new Product(product));
    this.loading = false;
  }

  public render() {
    if (this.loading || this.recommendations.length === 0) {
      return null;
    }

    const settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4,
      initialSlide: 0,
      // dotsClass: 'awesome-dots',
      customPaging: () => {
        return <span />;
      },
      prevArrow: <Left />,
      nextArrow: <Right />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: false,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    const products = this.recommendations.map((product) => (
      <ProductBlock key={product.slug} product={product} slider={true} />
    ));

    return (
      <div className="recommendations-block">
        <h2>{this.currentTitle}</h2>
        <div className="products-slick">
          <Slider {...settings}>{products}</Slider>
        </div>
      </div>
    );
  }
}
