import React from 'react';
import { observer, inject } from 'mobx-react';
import { observable, action, makeObservable } from 'mobx';

import Translate from '../Translate/Translate';

import ProductsStore from '../../stores/Products';

import Modal from '../Modal';

import './styles/SizeChart.scss';

interface Props {
  productSlug?: string;
  products?: ProductsStore;
}

@inject('products')
@observer
export default class Related extends React.Component<Props> {
  @observable currentProductSlug = '';
  @observable loading = true;
  @observable sizes = [];

  constructor(props: Props) {
    super(props);
    makeObservable(this);
  }

  componentDidMount() {
    this.loadSizes();
  }

  componentDidUpdate() {
    this.loadSizes();
  }

  async loadSizes() {
    const { productSlug, products } = this.props;

    if (productSlug === this.currentProductSlug) {
      return;
    }

    this.currentProductSlug = productSlug;

    this.setSizes(await products.loadSizeChart(productSlug));
  }

  @action setSizes(productSizes) {
    this.sizes = productSizes;
    this.loading = false;
  }

  public render() {
    if (this.loading || this.sizes.length === 0) {
      return null;
    }

    const sizeChart = (
      <div className="size-chart max-h-[500px] min-w-[280px] overflow-auto">
        <table className="table">
          <thead>
            <tr>
              {this.sizes['header'].map((th) => (
                <td key={th}>
                  <Translate component="size-chart" keyword={th} />
                </td>
              ))}
            </tr>
          </thead>

          <tbody className="overflow-auto">
            {this.sizes['sizes'].map((sizes) => (
              <tr key={sizes.size}>
                {sizes.size_chart.map((size) => (
                  <td key={`${size.size_type}-${size.size_value}`}>
                    {size.size_value}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );

    return (
      <Modal
        buttonClass="table hover:underline cursor-pointer"
        button={<Translate component="product" keyword="size-chart" />}
        content={sizeChart}
        contentTitle={<Translate component="product" keyword="sizes-chart" />}
        modalClass="center overflow-auto"
      />
    );
  }
}
