import React from 'react';
import Translate from '../Translate/Translate';
import FreeImg from '../../images/free.svg';
import LocalizedLink from '../LocalizedLink';
import ProductReturnImg from '../../images/product__return.svg';
import ProductBoxImg from '../../images/product__shipping.svg';
import QuestionMark from '../../images/question.svg';

interface Props {
  isBike?: boolean;
}

export default class ProductExtra extends React.Component<Props> {
  public render() {
    const { isBike } = this.props;

    return (
      <div className="mx-0 my-4">
        {isBike && (
          <div className="flex items-center mx-0 my-2.5">
            <img className="w-6 h-6 mr-2.5" src={QuestionMark} alt="" />
            <p className="leading-6">
              <Translate
                component="product"
                keyword="delivery-how-to-choose-bike"
              />
              <LocalizedLink
                className="text-rgreen underline ml-1"
                to="/blogi/jalgratta-suuruse-valimine"
              >
                <Translate
                  component="product"
                  keyword="rademar-delivery-link"
                />
              </LocalizedLink>
            </p>
          </div>
        )}

        <div className="flex items-center mx-0 my-2.5">
          <img className="w-6 h-6 mr-2.5" src={FreeImg} alt="" />
          <p className="leading-6">
            <Translate component="product" keyword="rademar-delivery" />
            <LocalizedLink
              className="text-rgreen underline ml-1"
              to="/blogi/rademari-valjastuspunkt"
            >
              <Translate component="product" keyword="rademar-delivery-link" />
            </LocalizedLink>
          </p>
        </div>
        <div className="flex items-center mx-0 my-2.5">
          <img className="w-6 h-6 mr-2.5" src={ProductBoxImg} alt="" />
          <p className="leading-6">
            {isBike ? (
              <Translate component="product" keyword="delivery-bike" />
            ) : (
              <Translate component="header" keyword="delivery-info" />
            )}
          </p>
        </div>
        <div className="flex items-center mx-0 my-2.5">
          <img className="w-6 h-6 mr-2.5" src={ProductReturnImg} alt="" />
          <p className="leading-6">
            <Translate component="header" keyword="return-exchange" />
          </p>
        </div>
      </div>
    );
  }
}
